@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
/* Colors */
:root {
  --first-color: #021fa1;
  --second-color: #fe564d;
  --black: #525252;
  --grey: #e9e8ed;
}

/* Font */
:root {
  --body-font: "Poppins", sans-serif;
  --big-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: 0.938rem;
  --weight-normal: 400;
  --weight-semibold: 600;
  --weight-bold: 700;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 0;
  height: 1vh;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--first-color);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--first-color);
}
html {
  scroll-behavior: smooth;
}
html,
body {
  font-family: var(--body-font);
}
section {
  width: 100vw;
  height: 100vh;
}
.map,
#map {
  border: none;
}
.header {
  background-color: var(--grey);
  height: 5rem;
}
.footer {
  background-color: var(--grey);
  height: 3rem;
}
.header .nav,
.footer .nav {
  display: flex;
  width: 90%;
  height: 100%;
  margin: auto;
  text-transform: uppercase;
  justify-content: space-between;
}
.header .nav .nav__menu .back__button,
.header .nav .nav__menu .menu__logo,
.header .menu__button {
  display: none;
}
.header .nav .nav__logo,
.footer .nav .nav__logo {
  display: flex;
  align-items: center;
}
.header .nav .nav__logo img {
  width: 3.75rem;
}
.footer .nav .nav__logo img {
  width: 2rem;
}
.header .nav .nav__logo span {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: var(--weight-bold);
  margin-left: 1.25rem;
}
.footer .nav .nav__logo span {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: var(--weight-bold);
  margin-left: 1.25rem;
}
.header .nav .nav__menu {
  display: flex;
  width: 70%;
  justify-content: space-between;
  font-weight: 625;
  font-size: 1.25rem;
  align-items: center;
}
.header .nav .nav__menu .nav__item {
  list-style: none;
}
.header .nav .nav__menu .nav__item .nav__link {
  text-decoration: none;
  color: var(--black);
}

/* main{
  padding-top: 5%;
} */
#home,
#info_empresa {
  justify-content: center;
}
#home .div__home,
#info_empresa .div__info_empresa {
  width: 90%;
  height: 80%;
  margin: auto;
  padding-top: 10%;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-self: center;
}
#home .div__home .div__home__text .home__title,
#info_empresa .div__info_empresa .div__info_empresa__text .info_empresa__title {
  font-weight: var(--weight-semibold);
  font-size: 2.15rem;
  color: var(--first-color);
}
#home .div__home .div__home__text .home__title span,
#info_empresa
  .div__info_empresa
  .div__info_empresa__text
  .info_empresa__title
  span {
  /* font-size: 1rem; */
  font-weight: 400;
}
#home .div__home .div__home__text .home__radio__links,
#info_empresa
  .div__info_empresa
  .div__info_empresa__text
  .info_empresa__radio__links {
  display: flex;
}
#home .div__home .div__home__text .home__radio__links img,
#info_empresa
  .div__info_empresa
  .div__info_empresa__text
  .info_empresa__radio__links
  img {
  width: 12.5rem;
}
#home .div__home .ilustra_home,
#info_empresa .div__info_empresa .ilustra_info_empresa {
  width: 100%;
  align-self: auto;
  /* align-self: stretch; */
}
#info_empresa .div__info_empresa .div__info_empresa__text {
  margin-left: 2rem;
}

#rio_grande,
#teculutan {
  background-image: url("./assets/images/back_sec1.svg");
}
#progresiva {
  background-image: url("./assets/images/back_sec2.svg");
}
#rio_grande,
#progresiva,
#teculutan {
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}
#rio_grande .div__rio_grande .card__section .card__icon,
#progresiva .div__progresiva .card__section .card__icon,
#teculutan .div__teculutan .card__section .card__icon {
  width: 5rem;
}
#rio_grande .div__rio_grande .card__section,
#progresiva .div__progresiva .card__section,
#teculutan .div__teculutan .card__section {
  background: #ffffff;
  box-shadow: 1px 1px 100px rgba(0, 0, 0, 0.1);
  border-radius: 42px;
  width: 17rem;
  min-width: 15rem;
  min-height: 18rem;
  padding: 1rem;
  position: absolute;
}
#rio_grande .div__rio_grande,
#progresiva .div__progresiva,
#teculutan .div__teculutan {
  position: relative;
  height: 100%;
  width: 100%;
}
#rio_grande .div__rio_grande .card__section:nth-child(1),
#teculutan .div__teculutan .card__section:nth-child(1) {
  bottom: 10rem;
  left: 15rem;
}
#rio_grande .div__rio_grande .card__section:nth-child(2),
#teculutan .div__teculutan .card__section:nth-child(2) {
  top: 8rem;
  left: 40%;
}
#rio_grande .div__rio_grande .card__section:nth-child(3),
#teculutan .div__teculutan .card__section:nth-child(3) {
  bottom: 3rem;
  left: 40%;
  height: 15rem !important;
  min-height: 15rem;
}
#rio_grande .div__rio_grande .card__section:nth-child(4),
#teculutan .div__teculutan .card__section:nth-child(4) {
  top: 13rem;
  right: 15rem;
  text-align: center;
}
#rio_grande .div__rio_grande .card__section:nth-child(4) img,
#teculutan .div__teculutan .card__section:nth-child(4) img {
  margin-top: 1rem;
}
#rio_grande .div__rio_grande .button__section,
#teculutan .div__teculutan .button__section {
  background-color: var(--second-color);
  color: #fff;
  text-transform: uppercase;
  border: 0;
  border-radius: 7px;
  font-size: 2rem;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  width: 19rem;
  bottom: 9rem;
  right: 15rem;
  box-shadow: 4px 4px 20px rgba(254, 86, 77, 0.3);
  cursor: pointer;
  font-weight: var(--weight-semibold);
}
#rio_grande .div__rio_grande .button__section:hover,
#teculutan .div__teculutan .button__section:hover {
  background-color: #ad3a34;
}

#progresiva .div__progresiva .card__section .card__icon {
  filter: invert(12%) sepia(84%) saturate(4513%) hue-rotate(233deg)
    brightness(77%) contrast(113%);
}
#progresiva .div__progresiva .card__section:nth-child(1) {
  bottom: 10rem;
  right: 15rem;
}
#progresiva .div__progresiva .card__section:nth-child(2) {
  top: 8rem;
  left: 40%;
}
#progresiva .div__progresiva .card__section:nth-child(3) {
  bottom: 3rem;
  left: 40%;
  height: 15rem !important;
  min-height: 15rem;
}
#progresiva .div__progresiva .card__section:nth-child(4) {
  top: 13rem;
  left: 15rem;
  text-align: center;
}
#progresiva .div__progresiva .card__section:nth-child(4) img {
  margin-top: 1rem;
}
#progresiva .div__progresiva .button__section {
  background-color: var(--first-color);
  color: #fff;
  text-transform: uppercase;
  border: 0;
  border-radius: 7px;
  font-size: 2rem;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  width: 19rem;
  bottom: 9rem;
  left: 15rem;
  box-shadow: 4px 4px 20px rgba(2, 31, 161, 0.3);
  cursor: pointer;
  font-weight: var(--weight-semibold);
}
#progresiva .div__progresiva .button__section:hover {
  background-color: #00146b;
}

#info_empresa
  .div__info_empresa
  .div__info_empresa__text
  .info_empresa__text
  ul {
  margin: 0;
  padding: 0;
}
#info_empresa
  .div__info_empresa
  .div__info_empresa__text
  .info_empresa__text
  ul
  li {
  list-style: none;
  display: flex;
}
#info_empresa
  .div__info_empresa
  .div__info_empresa__text
  .info_empresa__text
  ul
  li
  img {
  width: 1rem;
  margin-right: 1rem;
  filter: invert(78%) sepia(52%) saturate(7467%) hue-rotate(325deg)
    brightness(93%) contrast(115%);
}

#cobertura {
  /* height: 90%; */
  padding-top: 5%;
}
#cobertura .div__cobertura {
  width: 80%;
  height: 70%;
  margin: auto;
  text-align: center !important;
  text-align: --webkit-center;
  align-items: center;
  justify-content: center;
}
#cobertura .div__cobertura .cobertura__title {
  text-transform: uppercase;
  color: var(--first-color);
  font-weight: var(--weight-bold);
}
.header .nav .nav__menu .back__button .right__arrow {
  filter: invert(15%) sepia(83%) saturate(2885%) hue-rotate(226deg)
    brightness(85%) contrast(124%);
}

#precios {
  height: 70vh;
}
#precios .div__precios {
  text-transform: uppercase;
  padding-top: -15rem;
}
#precios .div__precios .precios__title {
  color: var(--second-color);
  text-align: center;
  font-size: 2.5rem;
}
#precios .div__precios .precios__cards {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-between;
}
#precios .div__precios .precios__cards .card {
  /* flex-shrink: 0; */
  background-color: #efefef;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  width: 16.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}
/* #precios .div__precios .precios__cards .card .card__title {
  font-size: 1rem;
} */
#precios .div__precios .precios__cards .card .card__title,
#precios .div__precios .precios__cards .card .card__price {
  text-align: center;
  color: var(--second-color);
  font-size: 1.4rem;
}
#precios .div__precios .precios__cards .card .card__text ul {
  list-style-image: url("./assets/icons/check-mark.svg");
}

@media only screen and (max-width: 1440px) {
  .header .nav .nav__menu {
    background-color: #e9e8ed;
    height: calc(100vh - 5rem);
    width: 15rem;
    position: fixed;
    right: -100%;
    display: grid;
    padding-bottom: 5rem;
    margin-top: 0;
    box-shadow: -1rem 0 40rem rgb(51 51 51 / 50%);
    transition: all 0.5s;
  }
  .show__menu {
    right: 0 !important;
    z-index: 9;
  }
  .header .nav .nav__menu .back__button {
    display: block;
    cursor: pointer;
  }
  .header .nav .nav__menu .back__button .right__arrow {
    width: 2rem;
  }
  .header .menu__button {
    display: block;
    cursor: pointer;
  }
  .header .menu__button .menu__icon {
    width: 5rem;
    position: absolute;
    top: 0.05rem;
    right: 1.5rem;
  }

  #rio_grande,
  #progresiva,
  #teculutan {
    background-size: 85% !important;
  }

  #rio_grande .div__rio_grande .card__section:nth-child(1) {
    left: 3rem;
  }
  #rio_grande .div__rio_grande .card__section:nth-child(2) {
    left: 26rem;
  }
  #rio_grande .div__rio_grande .card__section:nth-child(3) {
    left: 26rem;
  }
  #rio_grande .div__rio_grande .card__section:nth-child(4) {
    right: 3rem;
  }
  #rio_grande .div__rio_grande .button__section {
    right: 3rem;
  }

  #progresiva .div__progresiva .card__section:nth-child(1) {
    right: 3rem;
  }
  #progresiva .div__progresiva .card__section:nth-child(2) {
    left: 26rem;
  }
  #progresiva .div__progresiva .card__section:nth-child(3) {
    left: 26rem;
  }
  #progresiva .div__progresiva .card__section:nth-child(4) {
    left: 3rem;
  }
  #progresiva .div__progresiva .button__section {
    left: 3rem;
  }

  #teculutan .div__teculutan .card__section:nth-child(1) {
    left: 3rem;
  }
  #teculutan .div__teculutan .card__section:nth-child(2) {
    left: 26rem;
  }
  #teculutan .div__teculutan .card__section:nth-child(3) {
    left: 26rem;
  }
  #teculutan .div__teculutan .card__section:nth-child(4) {
    right: 3rem;
  }
  #teculutan .div__teculutan .button__section {
    right: 3rem;
  }

  #precios .div__precios .precios__cards {
    width: 90% !important;
  }
  #precios .div__precios .precios__cards .card {
    width: 12.5rem !important;
  }
}

@media only screen and (max-width: 786px) {
  #home {
    height: 50rem;
  }
  #home .div__home {
    padding-top: 5rem;
    grid-template-columns: unset;
  }
  #home .div__home .div__home__text {
    width: 100%;
  }
  #home .div__home .div__home__text .home__title {
    font-size: 1.75rem;
  }
  #home .div__home .ilustra_home {
    width: 15rem;
    padding-top: 1rem;
    text-align: center;
    margin: auto;
  }
  #home .div__home .div__home__text .home__radio__links img {
    width: 7rem;
    margin: auto;
  }

  #rio_grande .div__rio_grande {
    padding-top: 5rem;
  }
  #rio_grande .div__rio_grande .div__rio_grande__cards {
    display: flex;
    width: 90%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: auto;
    overflow-y: hidden;
    overflow-x: scroll;
    background-color: transparent;
  }
  #rio_grande .div__rio_grande div .card__section {
    width: 1rem;
    flex-shrink: 0;
    position: relative;
    margin-right: 1rem;
    height: 5rem;
    left: unset !important;
    right: unset !important;
    top: unset !important;
    bottom: unset !important;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  }
  #rio_grande .div__rio_grande div .card__section .card__icon {
    width: 2.5rem;
  }
  #rio_grande .div__rio_grande div .card__section .card__title {
  }
  #rio_grande .div__rio_grande div .card__section .card__text {
    font-size: 0.945rem;
  }
  #rio_grande .div__rio_grande .card__section:nth-child(1) {
    order: 2;
  }
  #rio_grande .div__rio_grande .card__section:nth-child(2) {
    order: 3;
  }
  #rio_grande .div__rio_grande .card__section:nth-child(3) {
    order: 4;
  }
  #rio_grande .div__rio_grande .card__section:nth-child(4) {
    order: 1;
  }
  #rio_grande .div__rio_grande .card__section {
    width: 1rem;
  }

  #progresiva .div__progresiva {
    padding-top: 5rem;
  }
  #progresiva .div__progresiva .div__progresiva__cards {
    display: flex;
    width: 90%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: auto;
    overflow-y: hidden;
    overflow-x: scroll;
    background-color: transparent;
  }
  #progresiva .div__progresiva div .card__section {
    width: 1rem;
    flex-shrink: 0;
    position: relative;
    margin-right: 1rem;
    height: 5rem;
    left: unset !important;
    right: unset !important;
    top: unset !important;
    bottom: unset !important;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  }
  #progresiva .div__progresiva div .card__section .card__icon {
    width: 2.5rem;
  }
  #progresiva .div__progresiva div .card__section .card__title {
  }
  #progresiva .div__progresiva div .card__section .card__text {
    font-size: 0.945rem;
  }
  #progresiva .div__progresiva .card__section:nth-child(1) {
    order: 2;
  }
  #progresiva .div__progresiva .card__section:nth-child(2) {
    order: 3;
  }
  #progresiva .div__progresiva .card__section:nth-child(3) {
    order: 4;
  }
  #progresiva .div__progresiva .card__section:nth-child(4) {
    order: 1;
  }

  #teculutan .div__teculutan {
    padding-top: 5rem;
  }
  #teculutan .div__teculutan .div__teculutan__cards {
    display: flex;
    width: 90%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: auto;
    overflow-y: hidden;
    overflow-x: scroll;
    background-color: transparent;
  }
  #teculutan .div__teculutan div .card__section {
    width: 1rem;
    flex-shrink: 0;
    position: relative;
    margin-right: 1rem;
    height: 5rem;
    left: unset !important;
    right: unset !important;
    top: unset !important;
    bottom: unset !important;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  }
  #teculutan .div__teculutan div .card__section .card__icon {
    width: 2.5rem;
  }
  #teculutan .div__teculutan div .card__section .card__title {
  }
  #teculutan .div__teculutan div .card__section .card__text {
    font-size: 0.945rem;
  }
  #teculutan .div__teculutan .card__section:nth-child(1) {
    order: 2;
  }
  #teculutan .div__teculutan .card__section:nth-child(2) {
    order: 3;
  }
  #teculutan .div__teculutan .card__section:nth-child(3) {
    order: 4;
  }
  #teculutan .div__teculutan .card__section:nth-child(4) {
    order: 1;
  }
  #info_empresa {
    height: 55rem;
  }
  #info_empresa .div__info_empresa {
    padding-top: 5rem;
    display: grid;
    grid-template-columns: unset;
    position: relative;
  }
  #info_empresa .div__info_empresa .ilustra_info_empresa {
    width: 10rem;
    margin: auto;
    order: 2;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  #info_empresa .div__info_empresa .div__info_empresa__text {
    margin: 0;
    order: 1;
  }
  #info_empresa
    .div__info_empresa
    .div__info_empresa__text
    .info_empresa__title {
    margin-top: 0;
    margin-bottom: 0.75rem;
    font-size: 2rem;
  }
  #info_empresa
    .div__info_empresa
    .div__info_empresa__text
    .info_empresa__text
    ul {
    margin: 0;
  }
  #info_empresa
    .div__info_empresa
    .div__info_empresa__text
    .info_empresa__text
    ul
    li {
    margin: 0;
  }
  #info_empresa
    .div__info_empresa
    .div__info_empresa__text
    .info_empresa__text
    ul
    li
    img {
    height: 1rem;
    margin-top: 1.25rem;
  }
  #info_empresa
    .div__info_empresa
    .div__info_empresa__text
    .info_empresa__text
    ul
    li
    p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  #cobertura .div__cobertura {
    padding-top: 5rem;
  }
  #precios {
    height: 85vh;
  }
  #precios .div__precios .precios__title {
    width: 70%;
    margin: auto;
    text-align: center;
    margin-bottom: 2.5rem;
  }
  #precios .div__precios .precios__cards {
    overflow-y: hidden;
    overflow-x: scroll;
    width: calc(100% - 2rem) !important;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  #precios .div__precios .precios__cards .card {
    flex-shrink: 0;
    margin-right: 2rem;
    height: auto;
  }
}
